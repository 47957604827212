@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'HelixaBlack';
  src: url('./Helixa-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'HelixaRegular';
  src: url('./Helixa-Regular.ttf') format('truetype');
}

.font-helixa-black {
  font-family: 'HelixaBlack', sans-serif;
}

.font-helixa-regular {
  font-family: 'HelixaRegular', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.btn-slide-show {
  @apply bg-white/50 text-slate-900 h-12 overflow-hidden text-center transition-all duration-300 cursor-pointer rounded-full;
}

.btn-slide-show:hover .btn-slide-show-inner {
  @apply -left-[580px];
}

.btn-slide-show-text1 {
  @apply transition-all duration-300 py-4 text-slate-900;
}

.btn-slide-show:hover .btn-slide-show-text1 {
  @apply text-black py-2;
}

.btn-slide-show-inner {
  @apply absolute top-0 left-0 w-full h-full bg-slate-900 transition-all duration-300 bg-slate-900;
}

.btn-slide-show-text2 {
  @apply text-white absolute top-0 left-0 w-full h-full flex items-center justify-center;
}

.btn-slide-show:hover .btn-slide-show-text2 {
  @apply text-white/0;
}

@keyframes typewriter {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.typewriter-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  /* border-right: 3px solid; */
  /* Creates a blinking cursor */
  width: 0;
  /* Start with width 0 to hide text */
  opacity: 0;
  /* Start invisible */
  animation: typewriter 0.3s steps(20, end) forwards,
    fadeIn 0s forwards;
  line-height: 40px;
}

.line1 {
  animation-delay: 0s, 0s;
  /* Synchronize fadeIn and typewriter animations */
}

.line2 {
  animation-delay: 1s, 1s;
}

.line3 {
  animation-delay: 1.75s, 1.75s;
}

.spinning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#rotatingText {
  transform-origin: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text {
  fill: black;
  font-size: 16px;
  font-family: Arial, sans-serif;
}


#background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* Ensures it stays behind other content */
  pointer-events: none;
  /* Prevents interaction */
}

.maskthing {
  filter: blur(5px);
  filter: brightness(0.4);
  filter: contrast(200%);
  filter: drop-shadow(16px 16px 20px blue);
  filter: grayscale(50%);
  filter: hue-rotate(90deg);
  filter: invert(75%);
  filter: opacity(25%);
  filter: saturate(30%);
  filter: sepia(60%);
}